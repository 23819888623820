import { BodyText, ChevronDownIcon, Heading, Subheading, Title } from "@src/components/nessie-web";
import React, { useRef, useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import styled from "@emotion/styled";
import { Button, Space, theme } from "@src/components/nessie-web";
import window from "global/window";
import { Link } from "gatsby";
import Container from "@src/components/Container";
import useOnClickOutside from "@src/utils/useClickOutside";
import { mediaQueries, mediaQueriesMax } from "@src/styles/theme";
import SEO from "@src/components/SEO";

const TitleBanner = styled("div")`
  width: 100%;
  background-color: ${theme.colors.aqua50};
  height: 250px;
  display: grid;
  place-content: center;
  margin-bottom: 48px;
  h1 {
    color: white;
    text-shadow: 2px 2px 2px rgba(4, 76, 102, 0.5);
    text-align: center;
  }
  ${mediaQueriesMax[2]} {
    height: 90px;
    padding: 30px 15px;
    margin-bottom: 10px;
    h1 {
      font-size: 31px;
      font-weight: 400;
      line-height: 1.1;
    }
  }
`;

const StyledContainer = styled(Container)`
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  gap: 24px;
  margin-bottom: 80px;
  img {
    width: 120px;
    display: inline;
    margin-right: 12px;
  }
`;

const PrintButtonContainer = styled(Container)`
  width: fit-content;
  padding: 0 12px;
  ${mediaQueriesMax[2]} {
    margin: 0;
    margin-left: auto;
  }
  button:first-of-type {
    display: block;
    margin-left: auto;
  }
`;

const MenuToggleButton = styled("button")<{ isOpen: boolean }>`
  background-color: transparent;
  border: none;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  svg {
    transform: rotate(${(p) => (p.isOpen ? "180deg" : "0deg")});
  }
  ${mediaQueries[2]} {
    display: none !important;
  }
`;

const Aside = styled("aside")<{ isOpen: boolean }>`
  position: sticky;
  top: 89px;
  height: calc(100vh - 89px);
  min-width: 300px;
  overflow-y: auto;
  background: white;
  ${mediaQueriesMax[2]} {
    position: fixed;
    min-width: 0;
    width: 300px;
    left: ${(p) => (p.isOpen ? "0" : "-320px")};
    top: 89px;
    bottom: 0;
    z-index: 9;
  }

  ul {
    transition: transform 0.3s ease-out;
    will-change: transform;
    list-style: none;
    padding: 0 12px 0 0;
    li {
      padding-left: 0px;
    }
  }
`;

const DocumentLink = styled("li")<{ active: boolean }>`
  margin: 15px 0;
  padding: 0 10px 0 5px;
  line-height: 1.42857143;
  font-size: 20px;
  font-weight: 700;
  a {
    color: ${(p) => (p.active ? "auto" : "#333")};
  }
`;

const LinkItem = styled(BodyText)`
  margin-bottom: 10px;
  margin-left: 15px;
  font-size: 14px;
  font-weight: 400;
  a {
    color: #000;
    margin: 0;
  }
  &.active a {
    font-weight: 600;
    color: #00aeef;
  }
`;

const StyledHeading = styled(Heading)`
  font-size: 21px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 11px;
  margin-top: 22px;
  color: #363636;
`;

const StyledSubheading = styled(Subheading)`
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 11px;
  margin-top: 22px;
  color: #363636;
`;

type PrivacyPageProps = {
  pageContext: {
    allLegalPages: {
      [key: string]: {
        title: string;
        path: string;
      };
    };
    documentType: string;
    title: string;
    publicationDate: string;
    sections: {
      id: string;
      title: string;
      body?: string;
      subheadings?: {
        id: string;
        title: string;
        body: string;
      }[];
    }[];
  };
};

function handleAnchorClick(event, anchorId) {
  event.preventDefault();

  const targetElement = document.getElementById(anchorId);
  if (targetElement) {
    const topPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - 90;
    window.scrollTo({
      top: topPosition,
      behavior: "smooth",
    });
  }
}

const PrivacyPageLayout: React.FC<PrivacyPageProps> = ({ pageContext }) => {
  const [currentActiveIndex, setCurrentActiveIndex] = useState(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  function printPage() {
    if (window !== undefined) {
      window.print();
    }
  }

  useEffect(() => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionIndex = pageContext.sections.findIndex((section) => section.id === entry.target.id);
          setCurrentActiveIndex(sectionIndex);
        }
      });
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px",
      threshold: 0.7,
    });

    pageContext.sections.forEach((section) => {
      const sectionElement = document.getElementById(section.id);
      if (sectionElement) {
        observer.observe(sectionElement);
      }
    });

    return () => {
      pageContext.sections.forEach((section) => {
        const sectionElement = document.getElementById(section.id);
        if (sectionElement) {
          observer.unobserve(sectionElement);
        }
      });
    };
  }, [pageContext.sections]);

  useEffect(() => {
    const navLinkItems = document.querySelectorAll(".nav-link-item");
    if (currentActiveIndex !== null && navLinkItems[currentActiveIndex]) {
      const activeLinkRef = navLinkItems[currentActiveIndex];
      const asideElement = activeLinkRef.closest("aside");

      navLinkItems.forEach((linkItem, index) => {
        if (index === currentActiveIndex) {
          linkItem.classList.add("active");

          const linkTop = activeLinkRef.getBoundingClientRect().top;
          const asideTop = asideElement.getBoundingClientRect().top;
          const ulElement = asideElement.querySelector("ul");
          const translateY = parseInt(getComputedStyle(ulElement).getPropertyValue("--translateY") || "0");
          ulElement.style.setProperty("--translateY", `${translateY + (linkTop - asideTop)}px`);

          // Check if link is out of view below
          if (linkTop - asideTop > asideElement.clientHeight) {
            asideElement.scrollTop =
              linkTop - asideTop + asideElement.scrollTop - asideElement.clientHeight + activeLinkRef.clientHeight;
          }
          // Check if link is out of view above
          else if (linkTop < asideTop) {
            asideElement.scrollTop = linkTop - asideTop + asideElement.scrollTop;
          }
        } else {
          linkItem.classList.remove("active");
        }
      });
    }
  }, [currentActiveIndex]);

  return (
    <div>
      <SEO title={pageContext.title} />
      <TitleBanner>
        <Title size={2}>{pageContext.title}</Title>
      </TitleBanner>
      <PrintButtonContainer>
        <Button size="s" onClick={printPage}>
          Print policy
        </Button>
        <MenuToggleButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <span>Contents</span>
          <ChevronDownIcon />
        </MenuToggleButton>
      </PrintButtonContainer>
      <Space size="l" />
      <StyledContainer>
        <Aside isOpen={isOpen}>
          <ul>
            {Object.keys(pageContext.allLegalPages).map((key, index) => {
              const pageInfo = pageContext.allLegalPages[key];
              return (
                <DocumentLink key={index} active={key === pageContext.documentType}>
                  <Link to={pageInfo.path}>{pageInfo.title}</Link>
                  {key === pageContext.documentType && (
                    <ul css={{ marginTop: 6 }}>
                      {pageContext.sections.map((item, index) => (
                        <LinkItem
                          as="li"
                          className="nav-link-item"
                          key={index}
                          onClick={() => {
                            return setIsOpen(false);
                          }}
                        >
                          <a href={`#${item.id}`} onClick={(e) => handleAnchorClick(e, item.id)}>
                            {item.title}
                          </a>
                        </LinkItem>
                      ))}
                    </ul>
                  )}
                </DocumentLink>
              );
            })}
          </ul>
        </Aside>
        <div>
          <BodyText>Last updated: {pageContext.publicationDate}</BodyText>
          <Space size="l" />
          {pageContext.sections.map((item, index) => {
            return (
              <div key={index}>
                <StyledHeading id={item.id}>{item.title}</StyledHeading>
                {item.body && (
                  <div>
                    <ReactMarkdown>{item.body}</ReactMarkdown>
                  </div>
                )}
                {item.subheadings &&
                  item.subheadings.map((item, index) => (
                    <div key={index}>
                      <StyledSubheading id={item.id}>{item.title}</StyledSubheading>
                      <div>
                        <ReactMarkdown>{item.body}</ReactMarkdown>
                      </div>
                    </div>
                  ))}
              </div>
            );
          })}
        </div>
      </StyledContainer>
    </div>
  );
};

export default PrivacyPageLayout;
